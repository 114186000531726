// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-adults-index-js": () => import("./../../../src/pages/adults/index.js" /* webpackChunkName: "component---src-pages-adults-index-js" */),
  "component---src-pages-disclaimer-js": () => import("./../../../src/pages/disclaimer.js" /* webpackChunkName: "component---src-pages-disclaimer-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-infants-index-js": () => import("./../../../src/pages/infants/index.js" /* webpackChunkName: "component---src-pages-infants-index-js" */),
  "component---src-pages-laboratory-best-practices-index-js": () => import("./../../../src/pages/laboratory-best-practices/index.js" /* webpackChunkName: "component---src-pages-laboratory-best-practices-index-js" */),
  "component---src-pages-neonates-index-js": () => import("./../../../src/pages/neonates/index.js" /* webpackChunkName: "component---src-pages-neonates-index-js" */),
  "component---src-pages-other-considerations-js": () => import("./../../../src/pages/other-considerations.js" /* webpackChunkName: "component---src-pages-other-considerations-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */)
}

